<template>
  <div class="app-input" :class="inlineLabel ? 'inline-label' : ''">
    <label :for="name" class="col-form-label" :class="inlineLabel ? 'inline-label' : ''" v-if="showLabel">
      {{ label }}<span v-if="required" class="abs-required">*</span>
    </label>
      <input
        :type="type"
        class="form-control"
        :class="inlineLabel ? 'inline-input' : ''"
        :id="name" :name="name"
        :placeholder="translate.t(placeholder)"
        :value="value"
        :disabled="disabled"
        v-validate="validateRules"
        @input="changeInput($event.target.value)"
        v-bind="$attrs">
    <APPSVGIcon v-if="showClearIcon"  v-show="value" :click="()=>changeInput('')" name="icon-close" color="lightGray" width="20" height="20" style="position:relative;top: 10px; right: -5px;"/>
    <APPSVGIcon v-if="showSaveIcon" :tooltip="tooltipSave" :disabled="(!value || !anyChange || disabled) && !saveEnabled" :click="()=>saveValue('')" name="icon-save" color="#C5003E" width="20" height="20" style="position:relative; top: 10px; right: -5px;"/>
    <span v-show="errors.has(name)" class="help is-danger">{{ errorLabel }}</span>
  </div>
</template>

<script>
import APPSVGIcon from "../../icon/APPSVGIcon";
import i18n from "../../locale/i18n";
import {Utils} from "../../utils/utils";
import PendingSave from "../mixins/PendingSave";

export default {
  name: "APPInput",
  components:{
    APPSVGIcon
  },
  props: {
    tooltipSave:    { type: String},
    debounceMs:     {type: Number, default:0},
    label:          { type: String},
    showLabel:      { type: Boolean, default: true},
    name:           { type: String, required: true},
    placeholder:    {type:String, default:()=>'insert_a_value'},
    value:          [String,Number],
    type:           {type:String, default:()=>'number'},
    onChangeInput:  Function,
    validateRules:  {type: String | Object, default: () => 'numeric'},
    disabled:       {type: Boolean, default: () => false},
    inlineLabel:  {type: Boolean, default: () => false},
    saveEnabled: {type: Boolean, default: () => true},
    readonly:{
      type:Boolean,
      default:()=>false
    },
    showClearIcon:{type: Boolean, default:false},
    showSaveIcon:{type: Boolean, default:false},
    saveClicked:Function,
    inputStyle:     {type:Object, default:()=>{return {}} }
  },
  data() {
    return {
      translate: i18n
    }
  },
  inject: ['$validator'],
  mixins:[PendingSave],
  computed:{
    required() {
      return Utils.checkIfRequired(this.validateRules)
    },
    errorLabel() {
      return this.errors.has(this.name) ? this.errors.first(this.name).replace(this.name, this.label) : "";
    }
  },
  methods: {
    changeInput(value) {
      if(this.debounceMs>0){
        setTimeout(()=>{
          this.$emit('input', value);
          if(this.onChangeInput)this.onChangeInput(value);
        }, this.debounceMs)
      }else{
        this.$emit('input', value);
        if(this.onChangeInput)this.onChangeInput(value);
      }
      this.changeDetection();
    },
    saveValue(){
      if(this.saveClicked)this.saveClicked();
    }
  }
}
</script>

<style>
.app-input a:hover > svg > g{
  fill:gray
}

.inline-input{
  float: left!important;
  width: 100px!important;
}

.inline-label{
  float: left;
  padding-right: 5px;
}
</style>
