<template>
  <div id="app" style="overflow: hidden; height: 100%;" v-resize="onResizeTop">
    <div v-if="showSpinner" class="load-spinner" :style="{ height: height + 'px'}">
      <vue-simple-spinner
        text-fg-color="#FFFFFF"
        :class="[
          'main-spinner',
          $store.getters.loadingMessageType=='SAVE' ? 'save-message' : $store.getters.loadingMessageType=='RELOAD' ? 'reload-message' : $store.getters.loadingMessageType=='INITIAL_LOAD' ? 'initial-load-message' : '' ]"
        :message="$store.getters.loadingMessageType=='SAVE' ? $t('saving_data') : $store.getters.loadingMessageType=='RELOAD' ? $t('reloading_db_data') : $store.getters.loadingMessageType=='INITIAL_LOAD' ? $t('initial_load_data') : $store.getters.loadingMessageType">
      </vue-simple-spinner>
    </div>
    <div v-if="$store.getters.isLogged && $store.getters.isAuthForDailySales">
      <div>
        <APPHead/>
        <APPHeader/>
      </div>
      <APPContent/>
      <footer id="footer" class="footer" :style="{ bottom: bottomFooter + 'px'}" v-if="showFooter" >
        <div class="copyright"><span class="iceOutTxt" id="form:Footer:j_id36">© 2022 - Copyright CORPORATE DAILY SALES</span>
        </div>
      </footer>
    </div>
    <NoAuthorized v-if="$store.getters.isLogged  && !$store.getters.isAuthForDailySales" :height="(height/2)-170"/>
  </div>
</template>
<script>
import VueSimpleSpinner from 'vue-simple-spinner'
import {Utils} from './utils/utils.js'
import english from 'vee-validate/dist/locale/en';
import resize from 'vue-resize-directive'
import APPHead from "./components/frame/topbar/APPHead";
import APPHeader from "./components/frame/topbar/APPHeader";
import APPContent from "./components/frame/content/APPContent";
import {EventBus} from "./utils/eventBus";
import {mapActions, mapGetters} from 'vuex'
import {CoreService} from "./rest/coreService";
import {User} from "./models/User";
import NoAuthorized from "./components/NoAuthorizated";
import APPNavigator from "./components/frame/topbar/APPNavigator";

export default {
  name: 'app',
  directives: {resize},
  inject: ['$validator'],
  components: {
    APPNavigator,
    NoAuthorized,
    APPContent,
    APPHeader,
    APPHead,
    VueSimpleSpinner
  },
  computed: {
    ...mapGetters({
      configData:"configData"
    }),
    showSpinner() {
      var show = this.$store.getters.loadingVisible || this.infoUserLoading || this.$store.getters.loadData;
      if(show){
        var body = document.body,
          html = document.documentElement;
        var h = Math.max(body.scrollHeight, body.offsetHeight,
          html.clientHeight, html.scrollHeight, html.offsetHeight);
        this.height = '' + h;
      }
      return show;
    },
    appContentHeight(){
      let h = this.height-(this.marginDivTop);
      return !!h ? parseFloat(h) : parseFloat(this.height) ;
    }
  },
  data() {
    return {
      width: Utils.getScreenWidth(),
      infoUserLoaded: false,
      infoUserLoading: true,
      scrollY: 0,
      height: Utils.getScreenHeight,
      bottomFooter: 0,
      showFooter: true,
      marginDivTop: 0,
    }
  },
  methods: {
    ...mapActions({
      changeLang: 'changeLang',
      setSMUserData: 'setSMUserData',
      setLoadData: "setLoadData",
      setIsLogged: "setIsLogged",
      setSessionData: "setSessionData",
      changeDailySales: "changeDailySales"
    }),
    handleScroll() {
      if (window.scrollY > this.scrollY) {
        if (this.showFooter)
          this.bottomFooter = this.bottomFooter - 8
      } else {
        if (this.bottomFooter < 0)
          this.bottomFooter = this.bottomFooter + 4
      }
      this.scrollY = window.scrollY
      if (this.scrollY === 0)
        this.bottomFooter = 0
      this.$nextTick(()=>this.$root.$emit('bv::hide::tooltip'))
    },
    onResizeTop(e) {
      this.height       = e.clientHeight;
      this.width        = e.clientWidth;
      console.log('App::onResizeTop height -> ' + this.height + ', width -> ' + this.width);
      console.log('App::onResizeTop Util height -> ' + Utils.getScreenHeight() + ', width -> ' + Utils.getScreenWidth());
      EventBus.onResizeApp();
    },
    onResize(e) {
      this.marginDivTop = e.clientHeight + 50
    },
    doLogin(){
      this.setLoadData(true);
      console.log('APP::doLogin start ...');
      CoreService.getToken().then(t=>{
        if(t.sessionData && t.userData && this.$store.getters.isLogged === null){
          console.log('APP::doLogin login done. Set all variables ..... ');
          let credential = new User(t.userData);
          this.setSMUserData(credential);
          this.setSessionData(t.sessionData);
          this.setIsLogged(true);
          this.setLoadData(false);
          console.log('APP::doLogin variables set!');
        }else{
          this.setLoadData(false);

          console.log('APP::doLogin login needs');
          CoreService.login().then(k => {
            window.location.href = k;
          });
        }

        console.log('APP::doLogin end ...');
      });
    }
  },
  created() {
    console.log('APP::Created start ...');
    console.log('APP::Created lang loaded->' + this.$session.get('lang'))
    this.doLogin();
    this.$session.set('lang', 'en')
    this.$validator.localize('en', {messages: english.messages})
    this.$i18n.locale = 'en'
    this.changeLang('en')

    window.addEventListener('scroll', this.handleScroll)
    this.marginDivTop = window.clientHeight + 10
    setTimeout(function(){
      this.infoUserLoading = false
      this.infoUserLoaded = false
    }.bind(this), 2000)
  },
  mounted(){
    console.log('APP::Mounted start ...');
    let tt = this.appContentHeight;
    console.log('APP::Mounted end ...');
  },
  destroyed() {
    // window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style scoped>
.nav-bar .container {
  padding-left: 0 !important;
}

.main-spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -16px;
  margin-left: -16px;
  z-index: 9999999999999999999999;
}
.main-spinner.save-message{
  left:48%
}
.main-spinner.reload-message{
  left:45%
}
.main-spinner.initial-load-message{
  left:45%
}

.load-spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1031;
  background-color: rgba(0, 0, 0, 0.4)
}

.copyright {
  text-align: center;
  padding: 0px !important;
  color: #fff;
}

.copyright .iceOutTxt {
  color: #fff;
}

.iceOutTxt, .iceOutLbl {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  color: #000000;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
}


</style>
