<template>
  <div style="background: #C5003E; position: relative; height: 40px;padding-top: 5px;">
    <h3 class="mt-0 mb-1" style="color: white;text-align: center; width: 100%; font-weight: 900">CORPORATE DAILY SALES</h3>
  </div>
</template>

<script>
import APPRightHeader from "./APPRightHeader";;
export default {
  name: "APPHeader",
  components: {APPRightHeader},
}
</script>

<style scoped>
.header{
  margin: 15px 0!important;
}
</style>
