<template>
  <div>
    <div>
    <label :for="name" class="col-form-label" v-if="showLabel" :style="{float: float}">
      {{ label }}<span v-if="required" class="abs-required">*</span> </label>
      <select :name="name"
              style=" width: auto; margin-left: 15px;"
              :style="{float: float}"
              class="form-control"
              :class="{'is-danger': errors.has(name)}"
              @input="$emit('input',$event.target.value)"
              @change.prevent="changeInput($event.target.value)"
              :disabled="disabled"
              v-bind="$props"
              v-validate="validateRules">
        <option v-if="emptyValue" class="no-left-padding" value="">{{ translate.t(placeholder) }}</option>
        <option v-for="(option, index) in options" :selected="value==option.id" class="no-left-padding no-right-padding" :value="option.id">{{option.name}}</option>
      </select>
    </div>
    <span v-show="errors.has(name)" class="help is-danger">{{ errorLabel }}</span>
  </div>
</template>

<script>

import i18n from "../../locale/i18n";
import {Utils} from "../../utils/utils";

export default {
  name: "APPSelect",
  props: {
    label:          { type: String},
    showLabel:      { type: Boolean, default: true},
    name:           { type: String, required: true},
    placeholder:    {type:String, default:()=>'select_a_value'},
    value:          [String,Number],
    type:           {type:String, default:()=>'text'},
    options:        { type: Array, default: () => []},
    onChangeInput:  Function,
    validateRules:  {type: String | Object, default: () => ''},
    disabled:       {type: Boolean, default: () => false},
    emptyValue:     {type: Boolean, default:() => false},
    float:          {type:String, default:()=>'left'},
    readonly:{
      type:Boolean,
      default:()=>false
    },
    inputStyle:     {type:Object, default:()=>{} }
  },
  data() {
    return {
      translate: i18n,
      itemSelected: null,
    }
  },
  inject: ['$validator'],
  computed:{
    required() {
      return Utils.checkIfRequired(this.validateRules)
    },
    errorLabel() {
      return this.errors.has(this.name) ? this.errors.first(this.name).replace(this.name, this.label) : "";
    }
  },
  methods: {
    changeInput(event) {
      this.itemSelected = event;
      if(this.onChangeInput)this.onChangeInput(event);
    },
  }
}
</script>

<style scoped>

</style>
