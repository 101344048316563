

const state = {
 /* editingLegalEditingKPI: {},*/
  legalEntityLiteChose: null,
  legalEntities: [],
  totalDailySaleObj:null,
  dailySaleObj:null
};

const mutations = {
  'SET_EDITING_TOTAL_DAILY_SALE_OBJ'(state, data){
    state.totalDailySaleObj = data
  },
  'SET_EDITING_DAILY_SALE_OBJ'(state, data){
    state.dailySaleObj = data
  },
  'SET_LEGAL_ENTITY_LITE_CHOSE'(state, data){
    state.legalEntityLiteChose = data
  },
  'ADD_LEGAL_ENTITIES'(state, data){
    state.legalEntities = data
  },
  'RESET_DAILY_OBJECT'(state){
    state.totalDailySaleObj = null;
    state.dailySaleObj      = null;
  }
};

const actions = {
  setEditingTotalDailySaleObj:({commit}, data)=>{
    commit('SET_EDITING_TOTAL_DAILY_SALE_OBJ', data)
  },
  setEditingDailySaleObj:({commit}, data)=>{
    commit('SET_EDITING_DAILY_SALE_OBJ', data)
  },
  setLegalEntityLiteChose:({commit}, data)=>{
    commit('SET_LEGAL_ENTITY_LITE_CHOSE', data)
  },
  addLegalEntities:({commit}, data)=>{
    commit('ADD_LEGAL_ENTITIES', data)
  },
  resetDailyObject:({commit})=>{
    commit('RESET_DAILY_OBJECT')
  }
};
const getters = {
  totalDailySaleObj:(state)=>{
    return state.totalDailySaleObj;
  },
  dailySaleObj:(state)=>{
    return state.dailySaleObj;
  },
  legalEntities:(state)=>{
    return state.legalEntities;
  },
  legalEntityLiteChose:(state)=>{
    return state.legalEntityLiteChose;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
