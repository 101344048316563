import {Utils} from "../utils/utils";

export  class DailySalesObj{

  constructor(props) {
    this.entityCode     = !!props ? props.entityCode    : null;
    this.referenceDay   = !!props ? props.referenceDay  : null;
    this.nameOfTheDay   = !!props ? props.nameOfTheDay  : null;
    this.haQuantity     = !!props ? props.haQuantity    : null;
    this.haAmount       = !!props ? props.haAmount      : null;
    this.currency       = !!props ? props.currency      : null;
    this.isHoliday      = !!props && !!props.isHoliday ? props.isHoliday     : 'N';
    this.version        = !!props && !!props.version ? props.version       : 0;
    this.edit           = false;
  }


  static converterDailySaleToDailySlaveObject(ds, legalEntity, holidayArray) {

    return new DailySalesObj({
      entityCode: ds.entityCode,
      referenceDay: ds.referenceDay,
      nameOfTheDay: Utils.dayNameFromDate(ds.referenceDay),
      haQuantity: ds.haQuantity,
      haAmount: ds.haAmount,
      currency: legalEntity.currency,
      isHoliday: holidayArray.filter(t => ds.referenceDay == t).length > 0 ? 'Y' : 'N',
      version: ds.version
    });
  }

  static newDailySale(completeDate, legalEntity, holidayArray) {
   return new DailySalesObj({
      entityCode: legalEntity.entityCode,
      referenceDay: completeDate,
      nameOfTheDay: Utils.dayNameFromDate(completeDate),
      haQuantity: 0,
      haAmount:  0,
      currency: legalEntity.currency,
      isHoliday: holidayArray.filter(t=>completeDate == t).length > 0 ? 'Y': 'N',
      version: 0
    });
  }
}
