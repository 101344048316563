import axios from 'axios';
import store from '../store/store'
import i18n from '../locale/i18n'
const vue   = require('vue');



var service = {
  getCall(url, callback, showLoading, errorCallback, loadingMessageType){
    if(showLoading)store.dispatch('showLoading', {show:true,messageType:loadingMessageType});
    axios.get(url, {headers: {sessionData: store.getters.sessionData}})
      .then((response)  =>  {
        if(showLoading)store.dispatch('showLoading', false);
        callback(response);
      }, (error)  =>  {
        store.dispatch('showLoading', false);
        if(error && error.stack && error.stack.indexOf('Error: Network Error') > -1){
          vue.default.prototype.$toastr('error', i18n.t('network_error'), i18n.t('error'));
        }else{
          vue.default.prototype.$toastr('error', error?.response?.data?.errMsg ? error.response.data.errMsg : i18n.t('server_error'), i18n.t('error'));
          if(errorCallback) errorCallback(error);
          console.error(error)
          console.groupEnd()
        }
      })
  },
  postCall(url, request, callback, showLoading, errorCallback, loadingMessageType){
    if(showLoading){
      store.dispatch('showLoading', {show:true,messageType:loadingMessageType});
    }
    axios.post(url, request, {headers: {sessiondata: store.getters.sessionData}})
      .then((response)  =>  {
        store.dispatch('showLoading', false);
        callback(response);
      }, (error)  =>  {
        if(showLoading) store.dispatch('showLoading', false);
        if(error && error.stack && error.stack.indexOf('Error: Network Error') > -1){
          vue.default.prototype.$toastr('error', i18n.t('network_error'), i18n.t('error'));
        }else{
          vue.default.prototype.$toastr('error', error?.response?.data?.errMsg ? error.response.data.errMsg : i18n.t('server_error'), i18n.t('error'));
          if (errorCallback) errorCallback(error);
        }
      })
  }
};

export const Service = service;
