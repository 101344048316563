export const it = {
'user_not_authorized': 'Utente non autorizzato',
  'choose_legal_entity':'Scegli una legal entity',
  'choose_month':'Scegli un mese',
  'select_a_value': 'Seleziona un valore',
  'username': 'Nome utente',
  'user_profile':'Profilo',
  'welcome': 'Benvenuto',
  'role': 'Ruolo',
  'administrator':'Amministratore',
  'operator': 'Operatore'
};
