import {Auth} from "./Auth";

export class User extends Auth{
  constructor(content) {
    super(content)
    this.nameID               = content ? content.name_id           : undefined;
    this.sessionID            = content ? content.session_index     : undefined;
    this.givenName            = content ? content.given_name        : undefined;
    this.surname              = content ? content.surname           : undefined;
    this.email                = content ? content.email             : '';
    this.attributes           = content && content.attributes ?  content.attributes : undefined;
    this.handleAuthorization  = this.groups;
  }

  set attributes(attributes){
    let groups_key =  (process.env.NODE_ENV === 'development')
      ? 'http://schemas.microsoft.com/claims/authnmethodsreferences'
      : 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groups'
    this.groups         = attributes && attributes[groups_key] ? attributes[groups_key]   : [];
    //if(this.email === 'ruben.deluca@cons.amplifon.com')
      //this.groups.push('GUAIT_PBI_DailySales_Portal_All');//TO_CHANGE RUBEN DA ELIMINARE
   }

  get username(){
    return (this.givenName && this.surname) ? this.givenName + ' ' + this.surname : this.nameID;
  }
}
