<template>
  <div>
    <div v-if="isTotal">
      <div class="row single-row">
        <div class="col-6 cell-content header text-center right-bordered" :style="{background: background}" >
          <p>{{ object.totalQuantity }}</p>
        </div>
        <div class="col-6 cell-content header text-center" :style="{background: background}" >
          <p>{{object.totalAmount}}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <form style="margin-top: 0px" >
        <div class="row single-row list" :class="{'color-text-white':object.isHoliday ==='Y', 'color-text-black':object.isHoliday === 'N', 'border-red':object.edit}" >
          <div class="col-3 cell-content small-height text-center right-bordered bottom-bordered" :style="{background: background}">
            <span><small>{{object.nameOfTheDay}}</small></span>
            <p>{{object.referenceDay }}</p>
          </div>
          <div class="col-3 small-height  input-div cell-content text-center right-bordered bottom-bordered" style="padding-top: 0px!important;" :style="{background: background}">
            <input type="number"   class="form-control without-bg text-center font-weight-bold" style="background: transparent" v-model="object.haQuantity" @input="changeInput()">
          </div>
          <div class="col-3  small-height   input-div  cell-content text-center right-bordered bottom-bordered" style="padding-top: 0px!important;" :style="{background: background}">
            <input type="number"   class="form-control without-bg text-center font-weight-bold" style="background: transparent" v-model="object.haAmount" @input="changeInput()">
          </div>
          <div class="col-3 small-height input-div cell-content text-center bottom-bordered right-bordered" style="padding-top: 10px!important;" :style="{background: background}">
            <p>{{object.currency}}</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PendingSave from "../../mixins/PendingSave";

export default {
  name: "RowValue",
  props:{
    isTotal:{
      type:Boolean,
      require: true
    },
    object:{
      type:Object,
      require: false
    }
  },
  mixins:[
    PendingSave
  ],
  computed:{
    background(){
      if(!!this.object && this.object.isHoliday === 'Y')
        return '#808080'
      return '#f0f0f0';
    }
  },
  methods:{
    changeInput(){
      console.log('RowValue::changeInput start ...');
      this.changeDetection();
      this.object.edit = true;
      console.log('RowValue::changeInput form edit set');
      console.log('RowValue::changeInput end');
    }
  }

}
</script>

<style scoped>
.single-row:not(.list){
  min-height: 50px;
  margin-top: 5px;
}
.single-row.list{
  height: 30px!important;
  margin-top: 5px;
}
.cell-content p{
  font-size: 16px;
  font-weight: bold;
}
.cell-content.header p{
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
}
.list .cell-content p{
  font-size: 12px;
  font-weight: bold;
  height: 15px;
  margin-bottom: 0!important;
  margin-top: -4px!important;
}
h6{
  color: black;
  padding-top: 5px;
}

.row{
  border: 2px solid white;
  padding: 0px 5px!important;
}
div.left-bordered{
  border-left: 2px white solid;
}
div.right-bordered{
  border-right: 2px white solid;
}
div.bottom-bordered{
  border-bottom: 2px white solid;
}
.color-text-white *{
  color: white;
}
.color-text-white *:focus{
  color: white;
}
.color-text-black *{
  color: black;
}
div.border-red div{
  -webkit-box-shadow:3px 3px 3px #f6004e;
  -moz-box-shadow:3px 3px 3px #f6004e;
  box-shadow:3px 3px 3px #f6004e;
}
.small-height{
  height: 32px!important;
}
.input-div{
  padding-top: 5px!important;
}
</style>
