
export  class DailySales{

  constructor(props) {
    this.entityCode     = !!props ? props.entityCode : null;
    this.referenceDay   = !!props ? props.referenceDay  : null;
    this.haQuantity     = !!props ? props.haQuantity    : null;
    this.haAmount       = !!props ? props.haAmount      : null;
    this.isHoliday      = !!props && !!props.isHoliday ? props.isHoliday : 'N';
    this.currency       = !!props && !!props.currency ? props.currency : null;
    this.version        = !!props && !!props.version ? props.version : 0;
  }
}
