<template>
  <div>
    <HeaderValue :is-total="false"/>
    <div  id="data-content" style="overflow-x: hidden;overflow-y: auto;" :style="{height:height+'px'}">
      <RowValue
        v-for="(item, $index) in dayInAMounth"
        :key="item.referenceDay"
        :is-total="false"
        :object="item"
      />
    </div>
  </div>
</template>

<script>
import {Utils} from "../../utils/utils";
import HeaderValue from "../frame/row/HeaderValue";
import RowValue from "../frame/row/RowValue";
import {AppService} from "../../rest/appService";
import {DailySalesObj} from "../../models/DailySaleObj";
import {EventBus} from "../../utils/eventBus";
import {ON_RESIZE_APP, REFRESH_DAILY_SALES, REFRESH_DAILY_SALES_WITH_LEGAL_ENTITY} from "../../utils/constants";
import {DailySales} from "../../models/DailySale";

export default {
  name: "DataContent",
  components: {RowValue, HeaderValue},
  props:{
    dateChoose:String,
  },
  data(){
    return {
      dailySalesHash:null,
      topPosition: 0,
      holidayData: []
    }
  },
  watch:{
    dateChoose(){
      console.log('DataContent::dateChoose start ...');
      if(!!this.dateChoose && !!this.$store.getters.legalEntityLiteChose) {
        console.log('DataContent::dateChoose is not null ... get holiday in month ' + this.dateChoose);
        this.getHolidayAndCreateHash()
      }
      console.log('DataContent::dateChoose end');
    }
  },
  computed:{
    height(){
      console.log('DataContent::height start ...');

      let h =  Utils.getScreenHeight()-(this.topPosition + 30);
      console.log('DataContent::height value ->' + h);
      return h;
    },
    dayInAMounth(){
      console.log('DataContent::dayInAMounth start ...');
      let arr = [];
      if(!!this.dailySalesHash) {
        console.log('DataContent::dayInAMounth dailySalesHash is not null');
        Object.keys(this.dailySalesHash).forEach(key=>{
          arr.push(this.dailySalesHash[key]);
        })
        console.log('DataContent::dayInAMounth end with arr lenght -> ' + arr.length);
      }
      this.$store.dispatch('setEditingDailySaleObj', arr)
      return arr;
    }
  },
  methods:{
    getHolidayAndCreateHash(){
      console.log('DataContent::getHolidayAndCreateHash start...');
      if(!!!this.$store.getters.legalEntityLiteChose.entityCode || !!!this.dateChoose)return;
      console.log('DataContent::getHolidayAndCreateHash pamameters valued ......... calling getHolidayByEntityCodeMonthAndYear');
      AppService.getHolidayByEntityCodeMonthAndYear(this.$store.getters.legalEntityLiteChose.entityCode, this.dateChoose, (data)=>{
        console.log('DataContent::getHolidayAndCreateHash pamameters valued ......... getHolidayByEntityCodeMonthAndYear called');
        if(!!data){
          this.holidayData = data;
          this.getDailySalesSavedByLegalEntityMonthAndYear();
          console.log('DataContent::getHolidayAndCreateHash getDailySalesSavedByLegalEntityMonthAndYear is called');
        }else{
          console.log('DataContent::getHolidayAndCreateHash data retrieve null');
        }
      })
    },
    getDailySalesSavedHash(dailySalesSaved){
      console.log('DataContent::getDailySalesSavedHash start...');
      console.log('DataContent::getDailySalesSavedHash dailySalesSaved lenght ' + (!!dailySalesSaved ? dailySalesSaved.length : 0));
      let hash = {};
      if(!!dailySalesSaved && dailySalesSaved.length >0){
        dailySalesSaved.forEach(t=>{
          hash[t.referenceDay] = new DailySales(t);
        })
      }
      console.log('DataContent::getDailySalesSavedHash hash created');
      console.log('DataContent::getDailySalesSavedHash end');
      return hash;

    },
    getDailySalesSavedByLegalEntityMonthAndYear(errorHash){
      console.log('DataContent::getDailySalesSavedByLegalEntityMonthAndYear start...');
      let legalEntityChoose = this.$store.getters.legalEntityLiteChose;
      let daysInMonth       = Utils.dayInAMonthYear(this.dateChoose);
      AppService.getDailySalesByLegalEntityMonthAndYear(legalEntityChoose.entityCode, this.dateChoose, (data)=>{
        console.log('DataContent::getDailySalesSavedByLegalEntityMonthAndYear pamameters valued ......... getDailySalesSavedByLegalEntityMonthAndYear called');
        this.dailySalesHash = {};
        let dailySalesSaved = this.getDailySalesSavedHash(data);

        for(let i = 1; i <= daysInMonth; i++){
          let key =  (i < 10 ? '0'+ i : i) + '-' + this.dateChoose;
          let dailyError = !!errorHash && errorHash[key] != undefined;
            this.dailySalesHash[key] =
              !!dailySalesSaved[key] ?
                DailySalesObj.converterDailySaleToDailySlaveObject(dailyError ? errorHash[key] : dailySalesSaved[key], legalEntityChoose, this.holidayData) :
                DailySalesObj.newDailySale(key, legalEntityChoose, this.holidayData);

          this.dailySalesHash[key].edit = dailyError;
          console.log('DataContent::getHolidayAndCreateHash data retrieved and hash created');
        }
        setTimeout(this.resizeApp, 1000);
        console.log('DataContent::getDailySalesSavedByLegalEntityMonthAndYear end');
      })
    },
    resizeApp(){
      console.log('DataContent::resizeApp start ...');
      let content = document.getElementById('data-content');
      console.log('DataContent::resizeApp content is null? ' + !!content);

      if(!!content) {
        console.log('DataContent::resizeApp get content position ...');
        const position = content.getBoundingClientRect();
        this.topPosition = position.top;
        console.log('DataContent::resizeApp get content position ' + JSON.stringify(position));
      }
      console.log('DataContent::resizeApp end');
    }
  },
  mounted(){
    console.log('DataContent::mounted start ...');
    console.log('DataContent::mounted handle position');
    console.log('DataContent::mounted end');
  },
  created(){
    console.log('DataContent::created start ...');
    console.log('DataContent::created listener bus on REFRESH_DAILY_SALES');
    EventBus.$on(REFRESH_DAILY_SALES, this.getDailySalesSavedByLegalEntityMonthAndYear);
    EventBus.$on(REFRESH_DAILY_SALES_WITH_LEGAL_ENTITY, this.getHolidayAndCreateHash);
    EventBus.$on(ON_RESIZE_APP, this.resizeApp);
  },
  beforeDestroy() {
    console.log('DataContent::beforeDestroy start ...');
    EventBus.$off(REFRESH_DAILY_SALES, this.getDailySalesSavedByLegalEntityMonthAndYear);
    EventBus.$off(REFRESH_DAILY_SALES_WITH_LEGAL_ENTITY, this.getHolidayAndCreateHash);
    console.log('DataContent::beforeDestroy end ');
  }

}
</script>

<style scoped>

</style>
