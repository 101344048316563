<template>
  <div style="padding: 20px 15px 20px 15px;">
    <div style="overflow:hidden;" class="resized-div">
          <div v-resize="onResize" style="margin-right: 10px">
            <DSActionContent
              :change-date="changeDate"
              :change-legal-entity="changeLegalEntity"
              :save-click="doSave"
              :reset-content="resetContentDailySales"
              :undo-click="doUndo"/>
            <br>
          </div>
          <div v-if="$store.getters.legalEntityLiteChose && !!this.dateChoose" >
            <div v-show="!!$store.getters.dailySaleObj && $store.getters.dailySaleObj.length > 0">
              <TotalContent style="margin-top: 15px;" :date-choose="dateChoose"/>
              <DataContent  :date-choose="dateChoose"/>
            </div>
            <div v-show="!!!$store.getters.dailySaleObj || $store.getters.dailySaleObj.length === 0" class="text-center" style="margin-top: 10%">
              <h3>Loading new daily sales ....</h3>
            </div>
          </div>
          <div v-else class="text-center" style="margin-top: 10%">
            <h3>Choose the criteria to load daily sales</h3>
          </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {AppService} from "../../../rest/appService";
import resize from "vue-resize-directive";
import DSActionContent from "../../contents/DSActionContent";
import TotalContent from "../../contents/TotalContent";
import DataContent from "../../contents/DataContent";
import {EventBus} from "../../../utils/eventBus";
import PendingSave from "../../mixins/PendingSave";
import {DailySales} from "../../../models/DailySale";

export default {
  name: "ABSContent",
  components: {DataContent, TotalContent, DSActionContent},
  mixins:[PendingSave],
  directives: {resize},
  data(){
    return {
      data:{},
      logLoaded:false,
      legalEntityLiteArray:[],
      dateChoose: null
    }
  },
  methods:{
    ...mapActions({
      showLoading:"showLoading"
    }),
    onResize(e) {
      this.headerHeight = e.clientHeight + 20;
    },
    resetContentDailySales(){
      this.$store.dispatch('setLegalEntityLiteChose',  undefined);
    },
    changeLegalEntity(legalEntityLite){
      if(legalEntityLite) {
        console.log('APPContent::changeLegalEntity  start ...');
        console.log('APPContent::changeLegalEntity store current legal entity lite');
        this.$store.dispatch('setLegalEntityLiteChose', legalEntityLite );
        this.legalEntityLite  = legalEntityLite;
        console.log('APPContent::changeLegalEntity  get stored month');
      }else{
        this.resetContentDailySales()
      }
    },
    changeDate(date){
      console.log('APPContent::changeDate  start ...');
      console.log('APPContent::changeDate  date -> ' + date);
      this.dateChoose = date;
    },
    doUndo(){
      this.resetChangeDetection();
      EventBus.refreshDailySales();
    },
    doSave(){
      console.log('APPContent::doSave  start ...');
      let dailySaleEdited = [];
      let currency        = this.legalEntityLite.currency;
      this.$store.getters.dailySaleObj.forEach(t=>{
        if(t.edit) {
          if (!!!t.haQuantity) t.haQuantity = 0;
          if (!!!t.haAmount) t.haAmount = 0;
          t.currency = currency;
          dailySaleEdited.push(t);
        }
      })
      let dailySaleToSave = dailySaleEdited.map(t=> new DailySales(t));
      console.log('APPContent::doSave  elements edit -> ' +  dailySaleToSave.length);
      console.log('APPContent::doSave  elements edit are -> ' +  JSON.stringify(dailySaleToSave));
      console.log('APPContent::doSave  call backend service ...');
      AppService.saveDailySales(dailySaleToSave, (data)=>{
        this.resetChangeDetection();
        EventBus.refreshDailySales(data.errorHash);
        if(data.success)
          this.$toastr('success', 'Daily sales are saved successfully', 'Action done');
        else
          this.$toastr('warning', 'There are changes made after your last access/refresh preventing the submission of the highlithed line(s). Please press refresh button and enter again.', 'Action with warning');
      });
      console.log('APPContent::doSave  end');
    }
  }
}
</script>

<style scoped>
h4{
  text-align: center;
  margin-top: 120px;
  color: grey;
}
.content-div{
  border: solid thin #C5003E;
  position: relative;
  padding: 25px 0px 0px 10px;
  border-top-width: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  min-height: 100px;
  height: auto;
}
.content-panel{
  overflow-y: auto!important;
  overflow-x: hidden;
  margin-bottom: -15px;
  padding-bottom: 30px;
}
.resized-div{
  margin: 10px 25% 10px;
}

@media (max-width: 1600px) {
  .resized-div{
    margin: 10px 15% 10px;
  }
}
@media (max-width: 1100px) {
  .resized-div{
    margin: 10px 10% 10px;
  }
}

@media (max-width: 850px) {
  .resized-div{
    margin: 10px 0% 10px;
  }
}

</style>
