<template>
  <div :class="params.customModalBodyClass!=null?params.customModalBodyClass:''">
    <div class="row" style="margin:0px;">
      <div class="abs-col-xs-5 icon-page-alert">
        <APPSVGIcon :name="icon" :color="color" width="36" height="36"/>
      </div>
      <div class="abs-col-xs-31 text-page-alert"  :style="params.textStyle">
        <span v-html="translate.t(params.messageKey)"></span>
      </div>
    </div>
    <div class="center-bottom" style="text-align: center">
      <button type="button" class="btn btn-primary btn-sm" v-if="params.okFunction" @click.prevent="ok()" style="width:100px;">{{ okLabel }}</button>
      <button type="button" @click.prevent="cancel()" v-if="params.cancelFunction" class="btn btn-sm btn-simple" style="width: 100px">{{ cancelLabel }}</button>
    </div>
  </div>
</template>

<script>
import i18n from '../../locale/i18n'
import APPSVGIcon from "../../icon/APPSVGIcon";
export default {
  name: "alert-dialog",
  components: {APPSVGIcon},
  props:['params'],
  data(){
    return{
      translate: i18n
    }
  },
  computed: {
    icon(){
      if(this.params.typeAlert === 'warning')      return 'icon-warning';
      else if(this.params.typeAlert === 'info')    return 'icon-info';
      else if(this.params.typeAlert === 'success') return 'icon-check-circle';
      else if(this.params.typeAlert === 'error')   return 'icon-error';
      else return 'icon-warning';
    },
    color(){
      if(this.params.typeAlert === 'warning')      return 'orange';
      else if(this.params.typeAlert === 'info')    return 'blue';
      else if(this.params.typeAlert === 'success') return 'green';
      else if(this.params.typeAlert === 'error')   return 'red';
      else  return 'red';
    },
    okLabel(){
      return this.params.okLabel ? this.translate.t(this.params.okLabel) :  this.translate.t('ok');
    },
    cancelLabel(){
      return this.params.cancelLabel ? this.translate.t(this.params.cancelLabel) :  this.translate.t('cancel');
    }
  },
  methods:{
    cancel(){
      if(this.params.cancelFunction) this.params.cancelFunction();
    },
    ok(){
      if(this.params.okFunction) this.params.okFunction();
    }
  }
}
</script>

<style scoped>
.icon-page-alert{
  margin: 15px 0px 0px 0px;
}
.text-page-alert{
  margin: 30px 0px 0px 0px;
  font-size: 14px;
}

@media (max-width:768px) {
  .icon-page-alert{
    margin: 20px 5px 5px 15px;
  }
  .text-page-alert{
    margin: 5px;
    font-size: 12px;
  }
}
.center-bottom{
  width: 100%;
}
</style>
<style>

.vDialogHeaderLabel{
  width: 100%!important;
}
.modal-header.vDialogHeader{
  background: #C5003E!important;
}

.vDialogFont.vDialog-close{
  color: white!important;
}

</style>
