import {Service} from "./service";
import store from "@/store/store";

var coreService = {
  login(){
    return new Promise((resolve, reject)=> {
      try {
        Service.getCall("/api/active_directory/login", response => {
          if (response.status === 200) {
            resolve(response.data)
          }
          reject()
        }, true, (e)=>{
          console.error(e)
          reject(e)
        });
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  },
  getToken(){
    return new Promise((resolve, reject)=> {
      try {
        Service.getCall("/api/active_directory/token", response => {
          if (response.status === 200) {
            resolve(response.data)
          }
          reject()
        }, true, (e)=>{
          console.error(e)
          reject(e)
        });
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  },
  logout(){
    return new Promise((resolve, reject)=> {
      try {
        Service.getCall("/api/active_directory/logout", response => {
          if (response.status === 200) {
            resolve(response.data)
          }
          reject()
        }, true, (e)=>{
          console.error(e)
          reject(e)
        });
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }
};

export const CoreService = coreService;
