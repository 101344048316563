
import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import i18n from './locale/i18n'
import '../public/assets/sass/material-dashboard.scss'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/it'
import locale from 'element-ui/lib/locale'
import VueScrollTo from 'vue-scrollto';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueSession from 'vue-session';
import VueToastr from '@deveodk/vue-toastr';
import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css';
import VeeValidate from 'vee-validate';
import vDialog from 'v-dialogs';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, {lodash: lodash })
Vue.use(VueScrollTo);

/*

 */
Vue.use(vDialog);
Vue.use(VueToastr, {
  defaultPosition: 'toast-bottom-full-width',
  defaultType: 'error',
  defaultTimeout: 5000
});

Vue.use(VueSession, { persist: true});
Vue.use(VeeValidate, {fieldsBagName: 'formFields', mode:'passive', events: ''} );
Vue.use(ElementUI);
Vue.use(BootstrapVue);
Vue.use(VueScrollTo);
locale.use(lang);

new Vue({
  el: '#app',
  store,
  i18n,
  render: h => h(App)
});
