import Vue from 'vue';
import Vuex from 'vuex';
import CommonStore from './modules/commonStore.js'
import AppStore from './modules/appStore.js'
import AuthStore from './modules/authStore.js'
Vue.use(Vuex);


export default new Vuex.Store({
  modules: {
    CommonStore,
    AppStore,
    AuthStore
  }
});
