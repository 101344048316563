import store from "../store/store";

export class Auth{
  constructor() {
    this.listDataPortal = (process.env.NODE_ENV === 'development')
      ? ["http://schemas.microsoft.com/ws/2008/06/identity/authenticationmethod/password"]
      : ["GUAIT_PBI_DailySales_Portal_All", "GUAIT_PBI_DailySales_Portal_AU", "GUAIT_PBI_DailySales_Portal_ATT",
        "GUAIT_PBI_DailySales_Portal_BAY", "GUAIT_PBI_DailySales_Portal_CN", "GUAIT_PBI_DailySales_Portal_IN",
        "GUAIT_PBI_DailySales_Portal_NZ", "GUAIT_PBI_DailySales_Portal_BL", "GUAIT_PBI_DailySales_Portal_EG",
        "GUAIT_PBI_DailySales_Portal_HU", "GUAIT_PBI_DailySales_Portal_IS", "GUAIT_PBI_DailySales_Portal_PL",
        "GUAIT_PBI_DailySales_Portal_AG", "GUAIT_PBI_DailySales_Portal_CL", "GUAIT_PBI_DailySales_Portal_CO",
        "GUAIT_PBI_DailySales_Portal_EC", "GUAIT_PBI_DailySales_Portal_AG", "GUAIT_PBI_DailySales_Portal_CL",
        "GUAIT_PBI_DailySales_Portal_CO", "GUAIT_PBI_DailySales_Portal_MX", "GUAIT_PBI_DailySales_Portal_EC",
        "GUAIT_PBI_DailySales_Portal_PA", "GUAIT_PBI_DailySales_Portal_MX", "GUAIT_PBI_DailySales_Portal_PA",
        "GUAIT_PBI_DailySales_Portal_US", "GUAIT_PBI_DailySales_Portal_URY"];
  }
  set handleAuthorization(groups) {
    console.log('Auth::handleAuthorization start ... ');
    if (groups && groups.length > 0 && store) {
      console.log('Auth::handleAuthorization checking groups ... ');
      this.isAuthForDailySales(groups).then(t => {
        console.log('Auth::handleAuthorization check permission up. Can I access?  -> ' + t);
        store.dispatch('changeDailySales', t)
      });
      store.dispatch('changeIsAdmin', groups.filter(t=> t.toLowerCase() === "GUAIT_PBI_DailySales_Portal_All".toLowerCase()).length >0);
      console.log('Auth::handleAuthorization end');
    }
  }

  async isAuthForDailySales(groups){
    console.log('Auth::isAuthForDailySales start ...');
    console.log('Auth::isAuthForDailySales groups -> ' + JSON.stringify(groups));
    return new Promise((resolve)=>{
      let isAuth;
      if(groups) {
        groups.forEach(t => {
          isAuth = this.listDataPortal.some(i=>i.toLowerCase() === t.toLowerCase());
          if(isAuth){
            console.log('Auth::isAuthForDailySales auth found');
            console.log('Auth::isAuthForDailySales end');
            resolve(true)
          }
        });
      }
      console.log('Auth::isAuthForDailySales auth not found');
      console.log('Auth::isAuthForDailySales end');
      resolve(false);
    })
  }

}
