import Vue from 'vue'
import VueI18n from 'vue-i18n';
import { en } from './langs/en.js';
import { it } from './langs/it.js';

var mergeJSON = require("merge-json") ;

Vue.use(VueI18n);

var dict =  new VueI18n({
    locale: 'en',
    silentTranslationWarn: true,
    messages: {
        en:  en,
        it: it
    }
});

export function addLocaleTranslation(msg) {
    var messages = mergeJSON.merge(dict.messages, msg);
    dict.setLocaleMessage("it", messages.it);
    dict.setLocaleMessage("en", messages.en);
    // console.log('Aggiunta -> ' + JSON.stringify(msg));
}


export default  dict;
