<template>
  <div>
    <HeaderValue :is-total="true"/>
    <RowValue :is-total="true" :object="totalObject"/>
  </div>
</template>

<script>
import HeaderValue from "../frame/row/HeaderValue";
import RowValue from "../frame/row/RowValue";
import {TotalDailySales} from "../../models/TotalDailySales";
export default {
  name: "TotalContent",
  components: {RowValue, HeaderValue},
  computed:{
    totalObject(){
      console.log('TotalContent::totalObject start ...');
      if(!!this.$store.getters.dailySaleObj){
        console.log('TotalContent::totalObject dailySaleObj is not null ');
        let totalQuantity  = 0;
        let totalAmount    = 0;
        let currency       = this.$store.getters.legalEntityLiteChose.currency;
        console.log('TotalContent::totalObject currency ->' + currency);
        this.$store.getters.dailySaleObj.forEach((t)=>{
          totalQuantity = parseFloat(totalQuantity) + parseFloat(t.haQuantity == '' || isNaN(t.haQuantity) || !!!t.haQuantity ? 0 : t.haQuantity);
          totalAmount   = parseFloat(totalAmount) + parseFloat(t.haAmount == '' || isNaN(t.haAmount) || !!!t.haAmount ? 0 : t.haAmount);
        })

        totalQuantity = totalQuantity +' ';
        totalAmount   = totalAmount + ' ' +  currency;
        console.log('TotalContent::totalObject totalQuantity -> ' +totalQuantity);
        console.log('TotalContent::totalObject totalAmount -> ' +totalAmount);
        return new TotalDailySales({
          totalQuantity,
          totalAmount
        })
      }
      console.log('TotalContent::totalObject dailySaleObj is null ');
      let total =  new TotalDailySales();
      console.log('TotalContent::totalObject end total ->' + JSON.stringify(total));
      return total;
    }
  }
}
</script>
