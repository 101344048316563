import {Service} from "./service";
import {LegalEntityLite} from "../models/LegalEntityLite";
import store from '../store/store'

var appService = {
  test(callback, showLoading=true){
    Service.getCall('/api/active_directory/test',(response)=>{
      callback ? callback(response) : '';
    }, showLoading, null, 'TEST APPLICATION')
  },
  getLegalEntities(callback, showLoading=true){
    Service.getCall('/api/webservice/getLegalEntities',(response)=>{
      const list = response.data.map(item=>new LegalEntityLite(item))
      store.dispatch('addLegalEntities', list)
      callback ? callback(list) : '';
    }, showLoading, null, 'GET LEGAL ENTITY')
  },
  getHolidayByEntityCodeMonthAndYear(entityCode, monthYear, callback,showLoading=true){
    Service.getCall('/api/webservice/getHolidayByEntityCodeMonthAndYear/' +entityCode+'/'+monthYear,(response)=>{
      callback ? callback(response.data) : '';
    }, showLoading, null, 'GET HOLIDAY BY COUNTRY')
  },
  getDailySalesByLegalEntityMonthAndYear(entityCode, monthYear, callback,showLoading=true){
    Service.getCall('/api/webservice/getDailySalesByLegalEntityMonthAndYear/' +entityCode+'/'+monthYear ,(response)=>{
      callback ? callback(response.data) : '';
    }, showLoading, null, 'GET DAILY SALES BY COUNTRY')
  },
  saveDailySales(array,callback, showLoading=true){
    Service.postCall('/api/webservice/saveDailySales',array,(response)=>{
      if(callback)
        callback(response.data);
    }, showLoading, null, 'SAVE DAILY SALES')
  }
};

export const AppService = appService;
