<template>
  <div class="navbar navbar-default abs-navigator" :class="{'shadow-effect':shadowEffect}" role="navigation">
    <div class="container" style="padding: 5px!important;">
      <div class="navbar-collapse abs-nav">
        <div class="row">
          <div class="col-xs-12 col-md-12" style="max-height: 25px;">
            <h6 style="color: white; font-weight: bold;padding-left: 10px;">Insert monthly data</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertDialog from "@/components/dialog/AlertDialog";
export default {
  name: 'APPNavigator',
  data(){
    return{
      shadowEffect:         false,
    };
  },
  methods:{
    handleScroll(){
      this.shadowEffect = window.pageYOffset>0
    },
    showPendingModsAlertDialog(){
      this.$vDialog.modal(AlertDialog, {
        dialogMaxButton: false,
        dialogCloseButton: true,
        title: this.$t('warning'),
        position: 'topCenter',
        width: 500,
        height: 150,
        params: {
          typeAlert: 'warning',
          textStyle: {marginTop: "30px"},
          messageKey: i18n.t('warn_pendingMods_ontabswitch'),
          customModalBodyClass: 'pending-mods-alert-popup',
          okFunction: () => {
            this.$vDialog.close();
          }
        },
      });
    }
  },
  created(){
    window.addEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>
.abs-navigator.shadow-effect{
  box-shadow:0px 5px 10px -4px #000000;
}
.abs-navigator {
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 10px;
  padding-right: 10px;
  border:none;
}
.abs-navigator .container{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-color: #C5003E!important;
  padding-left: 5px!important;
  background-color: #C5003E;
  border-bottom-width: 0px;
  width: 100%;
  max-width: 100% !important;
}

.abs-nav{
  padding-left: 0px!important;
}
.abs-nav ul{
  width: auto;
  display: inline-block;
}

@media (max-width:768px) {
  .abs-nav {
    padding-left: 15px !important;
  }
}
</style>
