import Vue from 'vue';
import {REFRESH_DAILY_SALES, ON_RESIZE_APP, REFRESH_DAILY_SALES_WITH_LEGAL_ENTITY} from "./constants";

export const EventBus = new Vue({
  methods: {
    onResizeApp(){
      this.$emit(ON_RESIZE_APP);
    },
    refreshDailySales(errorHash){
      this.$emit(REFRESH_DAILY_SALES, errorHash);
    },
    refreshDailySalesWithLegalEntity(){
      this.$emit(REFRESH_DAILY_SALES_WITH_LEGAL_ENTITY);
    }
  }
});
