<template>
  <div style="padding-top:0px;padding-bottom: 0px;">
    <div class="toolbarBottom" id="externalApps" style="position: relative; text-align: center">
        <img src="assets/img/logo.png" height="65">
      <APPRightHeader style="position: absolute; right: 20px; float: right; bottom: 5px; color: white"/>
    </div>
  </div>
</template>

<script>
import APPRightHeader from "./APPRightHeader";;
export default {
  name: "APPHead",
  components:{APPRightHeader}
}
</script>


<style scoped>
#externalApps {
  width: 100%;
  margin: 0px auto;
  margin-bottom: 0;
  height: 75px;
  background: #d7173f;
  padding-bottom: 20px;
}
</style>
