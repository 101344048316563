<template>
    <a class="app-svg-icon" style="margin:0px;" :style="{cursor: cursor}" @click="performClick" :href="href" target="_blank" >
      <svg xmlns="http://www.w3.org/2000/svg"
           v-b-tooltip.hover.ds750="{title:i18.t(tooltip), placement, boundary:'window', customClass: 'app-svg-icon' }"
           v-if="name"
           :width="width"
           :height="height"
           :viewBox="viewBox"
           :name="name"
           :style="{marginBottom: marginBottom+'px',marginLeft: marginLeft+'px'}"
           :aria-labelledby="name">
        <g :fill="disabled ? 'grey' : color">
          <component :is="name"></component>
        </g>
      </svg>
    </a>
</template>

<script>
import IconArrowDown from "./IconArrowDown"
import IconArrowUp from "./IconArrowUp"
import IconSave from "./IconSave"
import IconSearch from "./IconSearch"
import IconDelete from "./IconDelete"
import IconCalendarToday from "./IconCalendarToday"
import IconFileExcelOutline from "./IconFileExcelOutline"
import IconInfo from "./IconInfo"
import IconError from "./IconError"
import IconWarning from "./IconWarning"
import IconCheckCircle from "./IconCheckCircle"
import IconLogout from "./IconLogout"
import IconEye from "./IconEye"
import IconEyeOff from "./IconEyeOff"
import IconClose from "./IconClose"
import IconEdit from "./IconEdit"
import IconCaretUp from "./IconCaretUp"
import IconCaretDown from "./IconCaretDown"
import IconHammerWrench from "./IconHammerWrench"
import IconRefresh from "./IconRefresh"
import IconUndo from "./IconUndo"
import IconMicrosoftExcel from "./IconMicrosoftExcel"
import IconPin from "./IconPin"
import IconPinOff from "./IconPinOff"
import IconAddBox from "./IconAddBox"
import i18 from "../locale/i18n"
export default {
  name: "APPSVGIcon",
  components: {
    IconSave,
    IconArrowDown,
    IconArrowUp,
    IconSearch,
    IconCalendarToday,
    IconFileExcelOutline,
    IconDelete,
    IconWarning,
    IconError,
    IconInfo,
    IconCheckCircle,
    IconLogout,
    IconEye,
    IconEyeOff,
    IconClose,
    IconEdit,
    IconCaretUp,
    IconCaretDown,
    IconHammerWrench,
    IconRefresh,
    IconUndo,
    IconMicrosoftExcel,
    IconPin,
    IconPinOff,
    IconAddBox
  },
  props: {
    tooltip: String,
    placement:{
      type:String,
      default: ()=>"top",
      validator: (value)=>['top', 'topleft', 'topright', 'right', 'righttop', 'rightbottom', 'bottom', 'bottomleft', 'bottomright', 'left', 'lefttop','leftbottom '].indexOf(value)!=-1
    },
    permission: {
      type:Boolean,
      default: () => true
    },
    name: {
      type: String,
      required: true
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    color: {
      type: String,
      default: '#000'
    },
    stroke: {
      type: String,
      default: null,
    },
    strokeWidth: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    marginBottom: {
      type: Number,
      default: -3
    },
    marginLeft: {
      type: Number,
      default: 0
    },
    click: Function,
    href: String
  },
  data(){
    return{
      i18
    }
  },
  computed:{
    cursor(){
      if(this.disabled){
        return 'not-allowed'
      }
      return (this.click || this.href ) ? 'pointer' : 'normal'
    }
  },
  methods:{
    performClick(){
      if(this.click)this.click();
    }
  }
}
</script>

<style>
.app-svg-icon svg {
  display: inline-block;
  vertical-align: baseline;
}

.app-svg-icon a {
  list-style: none !important;
}
.app-svg-icon.tooltip{
  transition: opacity 0.15s linear, -webkit-opacity 0.15s linear, transform 0s ease, -webkit-transform 0s ease !important;
}
.app-svg-icon .tooltip-inner{
  background-color:#303133;
  color:#fff;
  font-size:12px;
  min-width: 0px;
  padding: 5px 10px;
}
.app-svg-icon.bs-tooltip-top .arrow::before,
.app-svg-icon.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color:#303133;
}
</style>
