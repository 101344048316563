export const en = {
  'user_not_authorized': 'User not authorized',
  'choose_legal_entity':'Choose a legal entity',
  'choose_month':'Choose a month',
  'select_a_value': 'Choose a value',
  'username': 'Username',
  'user_profile':'Profile',
  'welcome': '',
  'role': 'Role',
  'administrator':'Admin',
  'operator':'Operator'
}
