<template>
  <div class="total-style">
    <div v-if="isTotal">
      <div class="row total-header">
        <div class="col-6 text-center" >
          <h5>TOTAL QUANTITY</h5>
        </div>
        <div class="col-6 text-center" >
          <h5>TOTAL AMOUNT</h5>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row total-header">
        <div class="col-3 text-center" >
          <h5>Reference day</h5>
        </div>
        <div class="col-3 text-center" >
          <h5>HA Quantity</h5>
        </div>
        <div class="col-3 text-center" >
          <h5>HA Amount</h5>
        </div>
        <div class="col-3 text-center" >
          <h5>HA Currency</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderValue",
  props:{
    isTotal:{
      type:Boolean,
      require: true
    }
  }

}
</script>

<style scoped>

.total-style p{
  font-size: 16px;
  color: #C5003E;
}
.p-total{
  font-size: 14px!important;
  color: #777!important;
}
.total-header{
  background: #C5003E;
}
h5{
  color: white;
  padding-top: 5px;
  font-size: 20px;
  font-weight: 900;
}
</style>
