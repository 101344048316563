<template>
  <div class="row">
    <div class="col-lg-6 col-md-7 col-sm-12 col-12">
      <APPSelect
        v-bind="$props"
        :empty-value="true"
        :options="legalEntityLiteArray"
        @input="changeLegalEntityLocal"
        v-model="legalEntityLite.entityCode"
        name="choose_legal_entity"
        :label="$t('choose_legal_entity')"/>
    </div>
    <div class="col-lg-6 col-md-5 col-sm-12 col12">
      <APPSelect
        v-bind="$props"
        :options="allowedMonth"
        @input="changeAllowedMonth"
        v-model="currentDate"
        name="choose_month"
        :label="$t('choose_month')"/>
      <button
        type="button"
        class="btn btn-danger"
        v-if="!!legalEntityLite.entityCode" :disabled="!anyChange"
        @click="saveClickLocal" name="icon-save" style="position:relative; float: right; background: rgb(197, 0, 62); margin-top: 0"
      >SAVE</button>
      <APPSVGIcon v-if="!!legalEntityLite.entityCode" :disabled="!anyChange" tooltip='Undo changes' :click="undoClickLocal" name="icon-undo" color="#C5003E" width="24" height="24" style="position:relative; float: right"/>
      <APPSVGIcon v-if="!!legalEntityLite.entityCode" tooltip='Refresh' :click="undoClickLocal" name="icon-refresh" color="#C5003E" width="24" height="24" style="position:relative; float: right"/>
    </div>
  </div>
</template>

<script>
import {AppService} from "@/rest/appService";
import {LegalEntityLite} from "@/models/LegalEntityLite";
import APPSelect from "../form/APPSelect";
import APPSVGIcon from "@/icon/APPSVGIcon";
import APPInput from "../form/APPInput";
import PendingSave from "../mixins/PendingSave";
import moment from "moment";
import {Utils} from "@/utils/utils"
import {EventBus} from "../../utils/eventBus";

export default {
  name: "DSActionContent",
  components: {
    APPSelect,
    APPSVGIcon,
    APPInput
  },
  mixins:[PendingSave],
  props:{
    changeLegalEntity:{ type:Function, required: true},
    changeDate:{ type:Function, required: true},
    saveClick:{ type:Function, required: true},
    undoClick:{ type:Function, required: true},
    resetContent:{ type:Function, required: true},
  },
  data(){
    return {
      moment,
      Utils,
      legalEntityLiteArray: [],
      legalEntityLite: new LegalEntityLite(),
      currentLegalEntityCode: undefined,
      allowedMonth:[],
      currentDate: null
    }
  },
  methods: {
    changeLegalEntityLocal(evn) {
      console.log('DSActionContent::changeLegalEntityLocal Legal Entity -> ' + this.legalEntityLite.entityCode);
      if(this.anyChange){
        this.showConfimDialog('There are some changes. You\'ll lost them if you change Legal Entity. Do you want to proceed?', ()=>{
          this.resetChangeDetection()
          setTimeout(()=>{this.changeLegalEntityLocal(evn)}, 1000);
        }, ()=>{
          this.legalEntityLite.entityCode = this.currentLegalEntityCode;
        });
      }else {
        if(!evn || evn.length === 0){
          this.resetContent();
          return;
        }
        this.legalEntityLite        = _.clone(this.$store.getters.legalEntities.filter(t => t.entityCode === evn)[0]);
        this.currentLegalEntityCode = this.legalEntityLite.entityCode;
        this.changeLegalEntity(this.legalEntityLite);
        setTimeout(EventBus.refreshDailySalesWithLegalEntity,500);
      }
    },

    saveClickLocal(){
      if(this.anyChange)
        this.showConfimDialog('Please confirm the Daily Sales submission?', this.saveClick);
    },
    undoClickLocal(){
      if(this.anyChange)
        this.showConfimDialog('There are some changes. Do you confirm you want to discard them?', this.undoClick);
      else
        this.undoClick();
    },
    changeAllowedMonth(){
      console.log('DSActionContent::changeAllowedMonth Month id-> ' + this.currentDate);
      let date = this.allowedMonth.filter(t=>t.id==this.currentDate)[0].name;
      console.log('DSActionContent::changeAllowedMonth Month text -> ' + date);
      this.changeDate(date);
      console.log('DSActionContent::changeAllowedMonth end');
    },
    getLegalEntities() {
      console.log('DSActionContent::getLegalEntities start ...');
      AppService.getLegalEntities((le)=>{
        let user        = this.$store.getters.getSMUserData;
        const filterLE  =   [];
        console.log('DSActionContent::getLegalEntities check group');
        le.forEach(t=>{
          if(t.adGroups.filter( element => user.groups.includes(element)).length > 0)
            filterLE.push(t);
        })
        if(filterLE) {
          this.legalEntityLiteArray = filterLE
            .sort((a, b) => {
              if (a.region === b.region) {
                return a.entity  < b.entity;
              }
              return a.region < b.region ? -1 : 1
            });
          console.log('DSActionContent::getLegalEntities Legal Entity filtered');
          console.log('DSActionContent::getLegalEntities end');
        }
      })
    },
    fillProperDates(){
      console.log('DSActionContent::fillProperDates start ...');
      this.allowedMonth = [
        {id:1, name: Utils.getMonthYearBack(0)},
        {id:2, name:Utils.getMonthYearBack(1)},
        {id:3, name:Utils.getMonthYearBack(2)}
      ];
      this.currentDate = 1;
      this.changeAllowedMonth();
      console.log('DSActionContent::fillProperDates allowedMonth filled -> ' + JSON.stringify(this.allowedMonth));
      console.log('DSActionContent::fillProperDates end ...');
    }
  },
  created(){
    this.getLegalEntities();
    this.fillProperDates();
  }
}
</script>
