<template>
  <div style="display: inline-block;float: right;max-height: 20px;">
    <APPSVGIcon name="icon-logout" :width="14" :height="14" :click="logout" style="float: right" color="white" tooltip="Logout"/>
    <p id="app-user-profile-popover"  className="user" style="float: right;">{{ $t('welcome')}}
      <b><span class="font-weight-bold"> {{ $store.getters.getSMUserData.username }} </span></b>&nbsp;
    </p>
    <b-popover target="app-user-profile-popover" :title="$t('user_profile')" custom-class="app-user-profile-popover" triggers="hover">
      <div class="row mt-1 mb-1">
        <div class="abs-col-xs-15 text-nowrap text-right">{{ $t('username') }}:</div>
        <div class="abs-col-xs-21">{{$store.getters.getSMUserData.username}}</div>
      </div>
      <div class="row mt-1 mb-1">
        <div class="abs-col-xs-15 text-nowrap text-right">{{ $t('role') }}:</div>
        <div class="abs-col-xs-21">{{$store.getters.isAdmin ? $t('administrator'): $t('operator')}}</div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import APPSVGIcon from "../../../icon/APPSVGIcon";
import {CoreService} from "../../../rest/coreService";

export default {
  name: "AMPRightHeader",
  components: {APPSVGIcon},
  methods: {
    logout() {
      CoreService.logout().then(t => {
        this.$store.dispatch('setIsLogged', false);
        window.location.replace('https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0');
      })
    }
  }
}
</script>

<style scoped>
p.user {
  text-align: right;
  margin: 0px !important;
}

.right-navigator {
  float: right;
}



@media (max-width: 576px) {
  p.user {
    text-align: left;
  }

  p:first-child {
    text-align: left;
    margin-top: 10px !important;
  }

  .right-navigator {
    float: left;
  }

}
</style>
