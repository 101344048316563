import store from '../../store/store';
import AlertDialog from "../dialog/AlertDialog";
export default {
  name: 'PendingSave',
  computed:{
    anyChange(){
      return store.getters.pendingMods;
    }
  },
  methods:{
    changeDetection(){
      store.dispatch('changePendingMods', true);
    },
    resetChangeDetection(){
      setTimeout(function () {
        store.dispatch('changePendingMods', false);
      }.bind(this), 10)
    },
    showConfimDialog(message, okFunction, cancelFunction, title='Warning'){
      this.$vDialog.modal(AlertDialog, {
        dialogMaxButton: false,
        dialogCloseButton: true,
        title,
        position: 'topCenter',
        width: 500,
        height: 150,
        params: {
          typeAlert:'info',
          textStyle: {marginTop: "30px"},
          messageKey: message,
          customModalBodyClass: 'pending-mods-alert-popup',
          okLabel:this.$t('confirm'),
          okFunction: () => {
            okFunction()
            this.$vDialog.close();
          },
          cancelFunction: () => {
            if(cancelFunction)cancelFunction();
            this.$vDialog.close();
          }
        },
      });
    }
  }
}
