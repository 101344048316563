
const state = {
  isAuthForDailySales: false,
  isAdmin:false
};

const mutations = {
  'CHANGE_DAILY_SALES' (state, flag){
    state.isAuthForDailySales = flag;
  },
  'CHANGE_IS_ADMIN' (state, flag){
    state.isAdmin = flag;
  }
};

const actions = {
  changeDailySales:  ({commit }, data) => {
    commit('CHANGE_DAILY_SALES', data);
  },
  changeIsAdmin:  ({commit }, data) => {
    commit('CHANGE_IS_ADMIN', data);
  }
};

const getters = {
  isAuthForDailySales: (state) =>{
    return state.isAuthForDailySales;
  },
  isAdmin: (state)=>{
    return state.isAdmin;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
