import moment from "moment";

const DATE_FORMAT_0 = "DD/MM/YYYY HH:mm:ss"
var utils = {
  getScreenHeight() {
    let w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerHeight || e.clientHeight || g.clientHeight;
    return x;
  },
  getScreenWidth() {
    let w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth;
    return x;
  },
  checkIfRequired(object) {
    if (!object) {
      return false;
    }
    let validationString = typeof object === 'string' ? object : JSON.stringify(object);
    return validationString !== undefined && validationString.indexOf('required') > -1;
  },

  i18nTokenReplace(str, valorizedTokens){
    return valorizedTokens.reduce((acc, curr, index)=>{
      let ret = acc.replace("$"+index,curr)
      return ret;
    },str)
  },
  formatDate(date, format){
    return moment(date).isValid() ? moment(date).format(format) : ''
  },

  stringIsBlank(strValue){
    return strValue==null || strValue.trim() == ""
  },

  // elimino le righe con soli separatori (;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;)
  clearEmptyLinesFromJsonData(jsonArr){
    return jsonArr.filter(row=>!row.every(columnValue=>this.stringIsBlank(columnValue)))
  },

  formatTableFieldTimestamp(value){
    return this.formatDate(value.value, DATE_FORMAT_0)
  },

  fromIsoYearWeekToIsoWeek(value){
    return value!=null ? value.substring(6) : ""
  },


  isStringBlank(value){
    return value==null || (typeof value === 'string' && value.trim()==='')
  },
  getMonthYearBack(monthBack){
    return moment().subtract(monthBack, 'months').format('MM-YYYY');
  },
  dayInAMonthYear(monthYears){
    console.log('Utils::dayInAMonthYear start ...');
    return moment(monthYears, "MM-YYYY").daysInMonth();
  },
  dayNameFromDate(date){
    console.log('Utils::dayNameFromDate start ...');
    console.log('Utils::dayNameFromDate date ...' + date);
    console.log('Utils::dayNameFromDate date coverted ...' + moment(date, "DD-MM-YYYY").format("YYYY-MM-DD"));
    return moment(moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")).format('dddd');
  }
};

export const Utils = utils;
