<template>
  <div style="text-align: center;">
    <div :style="{paddingTop: height+'px'}">
      <div style="border: 1px #c2c2c2 solid; border-radius: 10px;width: 500px; padding: 15px;margin: 0 auto">
        <img src="assets/img/logo_big.png" height="100" >
        <p> {{ $t('user_not_authorized')}}</p>
        <button
          type="button"
          class="btn btn-sm btn-primary"
          style="margin:30px"
          @click.prevent="logout">
          Logout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {CoreService} from "../rest/coreService";

export default {
  name: "NoAuthorized",
  props:{
    onLogin:Function,
    height:0
  },
  methods:{
    logout() {
      CoreService.logout().then(t=>{
        this.$store.dispatch('setIsLogged', false);
        window.location.replace('https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0');
      })
    }
  }
}
</script>
