import {User} from "../../models/User";

const state = {
  loadingMessageType:"",
  showLoading: false,
  loadData: false,
  lang: 'it',
  isLogged:null,
  sessionData: {},
  smUserData: new User(),
  pendingMods: false,
};

const mutations = {
  'CHANGE_LANG' (state, lang){
    state.lang = lang;
  },
  'SHOW_LOADING' (state, {show, messageType}){
    state.showLoading = show;
    state.loadingMessageType = show ? messageType : "";
  },
  'SET_SM_USER_DATA' (state, data){
    state.smUserData = data;
  },
  'SET_LOAD_DATA' (state, loadData){
    state.loadData = loadData;
  },
  'SET_IS_LOGGED' (state, data){
    state.isLogged = data;
  },
  'SET_SESSION_DATA' (state, data){
    state.sessionData = data;
  },
  'CHANGE_PENDING_MODS' (state, data){
    state.pendingMods = data;
  },
};

const actions = {
  changeLang:  ({commit}, lang) => {
    commit('CHANGE_LANG', lang);
  },
  showLoading:  ({commit }, {show, messageType}) => {
    commit('SHOW_LOADING', {show, messageType});
  },
  setLoadData:  ({commit }, data) => {
    commit('SET_LOAD_DATA', data);
  },
  setSMUserData:  ({commit }, data) => {
    commit('SET_SM_USER_DATA', data);
  },
  setIsLogged:  ({commit }, data) => {
    commit('SET_IS_LOGGED', data);
  },
  setSessionData:  ({commit }, data) => {
    commit('SET_SESSION_DATA', data);
  },
  changePendingMods:  ({commit}, data) => {
    commit('CHANGE_PENDING_MODS', data);
  },
};

const getters = {
  loadingMessageType: (state) =>{
    return state.loadingMessageType;
  },
  loadingVisible: (state) =>{
    return state.showLoading;
  },
  lang: (state) =>{
    return state.lang;
  },
  getSMUserData: (state) =>{
    return state.smUserData;
  },
  loadData: (state)=>{
    return state.loadData;
  },
  isLogged: (state) =>{
    return state.isLogged;
  },
  sessionData: (state) =>{
    return state.sessionData;
  },
  pendingMods: (state) =>{
    return state.pendingMods;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
