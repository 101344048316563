
export class LegalEntityLite{
  constructor(obj){
    this.id               = obj ?  obj.entityCode       : undefined
    this.name             = obj ?  (obj.region + ' - ' + obj.entity) : undefined
    this.region           = obj ?  obj.region           : undefined
    this.entity           = obj ?  obj.entity           : undefined
    this.entityCode       = obj ?  obj.entityCode       : undefined
    this.currency         = obj && obj.currency ?  obj.currency : '€';
    this.adGroups         = obj ?  obj.adGroups         : [];
  }
}
